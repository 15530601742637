import useSWR from "swr";
import { DrugManufacturer, ResponseType } from "common/types";

export function useDrugManufacturers(id, reValidate = false, typeId = '') {
  let url = id
    ? `/api/drug-manufacturers?sorts=name:asc&filters%5Bpoint_of_sale_id%5D=${id}&includes=credentials`
    : "";
  if (typeId) {
    url += `&filters[type_id]=${typeId}`;
  }
  const { data, mutate: mutateDrugManufacturers, error } = useSWR<ResponseType<DrugManufacturer[]>>(
    url, 
    { revalidateOnFocus: reValidate }
  );

  return {
    drugManufacturers: data,
    isLoading: id ? !error && !data : false,
    error,
    mutateDrugManufacturers,
  };
}

export function useDrugManufacturersWithoutPagination(id, reValidate = false, typeId = '') {
  let url = `/api/drug-manufacturers?sorts=name:asc&filters[point_of_sale_id]=${id}&includes=credentials&rows=1000`;
  if (typeId) {
    url += `&filters[type_id]=${typeId}`;
  }
  const { data, mutate: mutateDrugManufacturers, error } = useSWR<ResponseType<DrugManufacturer[]>>(
    url, 
    { revalidateOnFocus: reValidate }
  );
  return {
    drugManufacturers: data,
    isLoading: !error && !data,
    error,
    mutateDrugManufacturers,
  };
}

export function useDrugManufacturersWithoutPaginationWithoutCredential(id, reValidate = false, typeId = '') {
  let url = `/api/drug-manufacturers?sorts=name:asc&filters[point_of_sale_id]=${id}&rows=1000`;
  if (typeId) {
    url += `&filters[type_id]=${typeId}`;
  }
  const { data, mutate: mutateDrugManufacturers, error } = useSWR<ResponseType<DrugManufacturer[]>>(
    url, 
    { revalidateOnFocus: reValidate }
  );
  return {
    drugManufacturers: data,
    isLoading: !error && !data,
    error,
    mutateDrugManufacturers,
  };
}

export function useDrugManufacturersByClientId(clientId, reValidate = false, typeId = '') {
  let url = `/api/drug-manufacturers?sorts=name:asc&filters%5Bclient_id%5D=${clientId}`;
  if (typeId) {
    url += `&filters[type_id]=${typeId}`;
  }
  const { data, mutate: mutateDrugManufacturers, error } = useSWR<ResponseType<DrugManufacturer[]>>(
    url, 
    { revalidateOnFocus: reValidate }
  );
  return {
    drugManufacturers: data,
    isLoading: !error && !data,
    error,
    mutateDrugManufacturers,
  };
}

export function useDrugManufacturersByClientIdWithoutPagination(clientId, reValidate = false, with_operations = '', typeId = '') {
  let url = `/api/drug-manufacturers?sorts=name:asc&filters[${with_operations}]?client_id=${clientId}&includes=credentials&rows=1000`;
  if (typeId) {
    url += `&filters[type_id]=${typeId}`;
  }
  const { data, mutate: mutateDrugManufacturers, error } = useSWR<ResponseType<DrugManufacturer[]>>(
    url, 
    { revalidateOnFocus: reValidate }
  );
  return {
    drugManufacturers: data,
    isLoading: !error && !data,
    error,
    mutateDrugManufacturers,
  };
}

export function useDrugManufacturersByPos(id, typeId = '') {
  let url = `/api/drug-manufacturers?sorts=name:asc&filters%5Bpoint_of_sale_id%5D=${id}`;
  if (typeId) {
    url += `&filters[type_id]=${typeId}`;
  }
  const { data, mutate: mutateDrugManufacturers, error } = useSWR<ResponseType<DrugManufacturer[]>>(
    url
  );

  return {
    drugManufacturers: data,
    isLoading: !error && !data,
    error,
    mutateDrugManufacturers,
  };
}

export function useAllDrugManufacturers(typeId = '') {
  let url = `/api/drug-manufacturers?sorts=name:asc`;
  if (typeId) {
    url += `&filters[type_id]=${typeId}`;
  }
  const { data, mutate: mutateDrugManufacturers, error } = useSWR<ResponseType<DrugManufacturer[]>>(
    url
  );

  return {
    drugManufacturers: data,
    isLoading: !error && !data,
    error,
    mutateDrugManufacturers,
  };
}