import React, { createContext, useContext } from "react";
import { useToast } from "@commons/EXComponentsLibrary";
import { IToastProps } from "@commons/EXComponentsLibrary/ExToast/interfaces/Toast.interface";
import { EX5942 } from "config/flags";

export interface SnackbarProviderValue {
  show: (options: SnackbarMessage) => void;
  hide: () => void;
}

const SnackbarContext = createContext<SnackbarProviderValue>(null);

interface SnackbarMessage {
  message: string;
  error?: boolean;
  success?:boolean;
  actionButton?: IToastProps['actionButton']
}

export function SnackProvider({ children }) {
  const { showToaster, clearToaster } = useToast();

  const show = ({ message, error, actionButton, success }: SnackbarMessage) => {
    showToaster({
      message: {
        description: message
      },
      type: error ? 'error' :  success && EX5942 ? 'success' : 'info',
      duration: 5000,
      actionButton
    })
  };

  const hide = () => {
    clearToaster();
  };

  return (
    <>
      <SnackbarContext.Provider value={{ show, hide }}>
        {children}
      </SnackbarContext.Provider>
    </>
  );
}

export function useSnackbar() {
  const context = useContext(SnackbarContext);

  if (context === undefined) {
    throw new Error("useSnackbar must be used within a SnackbarProvider");
  }

  return context;
}
