import useSWR from "swr";
import { ApiCredential, ResponseType } from "common/types";
import { objectToQueryParams } from "utils/string";
import { isBase64 } from "utils/base64";

export function useApiCredentials(query?) {
  const queryParams = objectToQueryParams(query);
  const { data, mutate: mutateApiCredentials, error } = useSWR<
    ResponseType<ApiCredential[]>
  >(`/api/api-credential?${queryParams}`);

  if (data) {
    data.data.forEach((credential) => {
      if (!credential.decoded_password) {
        credential.password = isBase64(credential.password)
          ? window.atob(credential.password)
          : credential.password;
        credential.decoded_password = true;
      }
    });
  }

  return {
    apiCredentials: data,
    isLoading: !error && !data,
    isEmpty: Array.isArray(data?.data) && data?.data.length === 0,
    error,
    mutateApiCredentials,
  };
}
