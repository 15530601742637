import { apiPharmaInstance } from 'utils/axios';

// post
export const postSendEmailVendors = async (drugManufacturerId, payload) => {
    try {
        return await apiPharmaInstance.post(`/api/drug-manufacturers/${drugManufacturerId}/request-authorization`, { ...payload });
    } catch (ex) {
        console.error(ex.message);
    }
};
