import { useTheme } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { changePassword } from 'api';
import { ButtonSolid } from 'commons/components/Button';
import { Box, Modal } from 'components';
import Button from 'components/Button';
import { EX4207, EX5057, EX5225 } from 'config/flags';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ModalProps, Status, useAsync, useUser } from 'utils/hooks';
import useChangeLanguage from 'utils/hooks/use-change-language';
import Regex from 'utils/regex';

const useStyles = makeStyles({
    root: {
        marginTop: 0,
        marginBottom: 30,
    },
});
const useStylesButton = makeStyles({
    root: { padding: 14 },
    disable: {
        '&.MuiButton-contained.Mui-disabled': {
            background: '#EEEEEE',
            color: '#D1D1D1',
        },
    },
    active: {
        '&.MuiButton-root ': {
            background: '#1DCAD3',
            color: 'white',
        },
        '&.MuiButton-root:hover': {
            background: '#1DCAD3',
            color: 'white',
        },
    },
});

function PasswordTextField({ register, errors, name, label, inputRef, inputProps }) {
    const { t } = useTranslation();
    const [showPassword, setShowPassword] = React.useState<boolean>(false);
    const classes = useStyles();
    return (
        <TextField
            style={{ color: '#eeeeee' }}
            classes={{
                root: classes.root,
            }}
            inputRef={inputRef}
            InputProps={{
                'aria-label': name,
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            variant="outlined"
            name={name}
            label={label}
            fullWidth
            size="small"
            type={showPassword ? 'text' : 'password'}
            helperText={errors[name]?.message ?? ''}
            error={Boolean(errors[name])}
            placeholder={!!EX5057 ? t('login.login44') : 'Ingrese una contraseña'}
        />
    );
}

interface ChangePasswordStatusProps {
    status?: 'success' | 'failed' | undefined;
    modal: ModalProps;
}

function ChangePasswordStatus({ modal, status }: ChangePasswordStatusProps) {
    const { palette } = useTheme();

    if (!status) {
        return null;
    }
    const EX3526 = process.env.NEXT_PUBLIC_EX3526 === 'true';
    const { t } = useTranslation();

    const errorChangePassword = EX3526
        ? t('home.changePassword.home_changePasswordTen', null)
        : 'Hubo un error al cambiar la contraseña.';

    const successChangePassword = EX3526 ? t('home.changePassword11', null) : 'Cambio de contraseña exitoso.';

    return (
        <>
            <Modal.Content>{status === 'success' ? successChangePassword : errorChangePassword}</Modal.Content>
            <Modal.Actions>
                <Button variant="text" onClick={modal.toggle}>
                    {EX5225 ? t('home.changePassword12') : 'ACEPTAR'}
                </Button>
            </Modal.Actions>
        </>
    );
}

function ChangePasswordForm({ modal }) {
    const { t } = useTranslation();
    const { userLocaleCountryCode } = useUser({});
    if (EX5225) useChangeLanguage(userLocaleCountryCode);
    const classes = useStylesButton();
    const { palette } = useTheme();
    const [respStatus, setRespStatus] = React.useState<'success' | 'failed' | undefined>(undefined);
    const [currentPasswordIsInvalid, setCurrentPasswordIsInvalid] = React.useState<boolean>(false);
    const { data, error, status, run } = useAsync({});
    const { register, handleSubmit, watch, formState, errors } = useForm({
        mode: 'onChange',
    });
    const EX3526 = process.env.NEXT_PUBLIC_EX3526 === 'true';
    const onSubmit = (params) => run(changePassword(params));

    React.useEffect(() => {
        switch (status) {
            case Status.Resolved:
                if (data?.status === 200) setRespStatus('success');
                break;
            case Status.Rejected:
                if (error?.response?.status === 400) setCurrentPasswordIsInvalid(true);
                else setRespStatus('failed');

                break;
        }
    }, [status]);

    const errorText = EX3526 ? t('home.changePassword8') : 'Debe contener 8 caracteres, letras y números.';

    const errorTextMachPassword = EX3526 ? t('home.changePassword9') : 'Las contraseñas no coinciden.';

    const passwordValidation = {
        required: true,
        minLength: {
            value: 8,
            message: errorText,
        },
        pattern: {
            value: Regex.password,
            message: errorText,
        },
    };

    if (respStatus) {
        return <ChangePasswordStatus {...{ modal }} status={respStatus} />;
    }
    const inputProps = {
        marginBottom: 20,
    };

    return (
        <Modal.Content
            classes={{
                root: classes.root,
            }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <div style={{ padding: '0px 0px' }}>
                    <PasswordTextField
                        inputProps={inputProps}
                        register={register}
                        errors={
                            currentPasswordIsInvalid
                                ? {
                                      current_password: {
                                          message: EX3526
                                              ? t('home.changePassword.home_changePasswordSeven')
                                              : 'Contraseña incorrecta.',
                                      },
                                  }
                                : errors
                        }
                        name="current_password"
                        label={EX3526 ? t('home.changePassword2') : 'Contraseña actual'}
                        inputRef={register(passwordValidation)}
                    />
                    <PasswordTextField
                        inputProps=""
                        register={register}
                        errors={errors}
                        name="new_password"
                        label={!!EX5057 ? t('home.changePassword3') : 'Nueva contraseña'}
                        inputRef={register(passwordValidation)}
                    />
                    <PasswordTextField
                        inputProps=""
                        register={register}
                        errors={errors}
                        name="repeat_password"
                        label={!!EX5057 ? t('home.changePassword4') : 'Repetir nueva contraseña'}
                        inputRef={register({
                            required: true,
                            validate: (value) => value === watch('new_password') || errorTextMachPassword,
                        })}
                    />
                </div>
                <Box display="flex" justifyContent="flex-end">
                    <Button
                        variant="text"
                        onClick={modal.toggle}
                        style={{
                            marginRight: '8px',
                        }}
                    >
                        <b> {EX3526 ? t('home.changePassword5') : 'Cancelar'} </b>
                    </Button>

                    {EX4207 ? (
                        <ButtonSolid
                            disabled={!formState.isValid}
                            height={40}
                            width={''}
                            text={EX3526 ? t('home.changePassword6') : 'CONFIRMAR'}
                            type="submit"
                        />
                    ) : (
                        <ButtonSolid
                            disabled={!formState.isValid}
                            height={40}
                            width={''}
                            text={EX3526 ? t('home.changePassword6') : 'CONFIRMAR'}
                        />
                    )}
                </Box>
            </form>
        </Modal.Content>
    );
}

function ChangePasswordModal({ modal }) {
    const { t } = useTranslation();
    const { userLocaleCountryCode } = useUser({});
    if (EX5225) useChangeLanguage(userLocaleCountryCode);
    return (
        <Modal fullWidth maxWidth="sm" open={modal.visibility} onClose={() => {}}>
            <Modal.Title>
                {' '}
                <span style={{ fontSize: '17px' }}>{!!EX5225 ? t('home.changePassword1') : 'Cambiar contraseña'}</span>
            </Modal.Title>
            <ChangePasswordForm
                {...{
                    modal,
                }}
            />
        </Modal>
    );
}

export default ChangePasswordModal;
