import React, { useEffect, useRef, useState } from 'react';
import { Form, InputGroup } from 'rsuite';
import styles from './AutocompleteGmaps.module.css';

const ExAutocompleteGmaps = ({
    label,
    name,
    isRequired,
    placeholder,
    error,
    showErrorIcon = false,
    onSelect,
    country,
    maxWidth,
    value,
    disabled,
    ...rest
}) => {
    const inputRef = useRef(null);
    const suggestionsRef = useRef(null);
    const [predictions, setPredictions] = useState([]);
    const [autocompleteService, setAutocompleteService] = useState(null);
    const [inputValue, setInputValue] = useState(value || '');
    const [showSuggestions, setShowSuggestions] = useState(false);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY}&libraries=places`;
        script.async = true;
        script.onload = () => {
            if (window.google) {
                setAutocompleteService(new window.google.maps.places.AutocompleteService());
            }
        };
        document.head.appendChild(script);

        return () => {
            if (script) {
                document.head.removeChild(script);
            }
        };
    }, []);

    useEffect(() => {
        if (autocompleteService && inputValue?.length > 0) {
            const options = {
                input: inputValue,
                componentRestrictions: country ? { country } : { country: 'AR' },
            };
            autocompleteService.getPlacePredictions(options, (predictions, status) => {
                if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                    setPredictions(predictions);
                } else {
                    setPredictions([]);
                }
            });
        } else {
            setPredictions([]);
        }
    }, [autocompleteService, inputValue, country]);

    const handleInputChange = (value) => {
        setShowSuggestions(true);
        setInputValue(value);
    };

    const handleSelect = (description) => {
        setInputValue(description);
        setPredictions([]);
        if (onSelect) {
            setShowSuggestions(false);
            onSelect(description);
        }
    };

    const handleClickOutside = (event) => {
        if (
            inputRef?.current &&
            !inputRef?.current.contains(event.target) &&
            suggestionsRef?.current &&
            !suggestionsRef?.current.contains(event.target)
        ) {
            setShowSuggestions(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (!value) {
            setInputValue('');
        }
    }, [value]);

    return (
        <>
            <Form.Group className={styles['form-input-group']} controlId={`form-input-${name}`}>
                <Form.ControlLabel>
                    <span className={styles['form-input-label']}>{label}</span>
                    {isRequired && <span className={styles['form-input-required']}>*</span>}
                </Form.ControlLabel>
                <InputGroup
                    style={{ width: maxWidth ? '100%' : 'unset' }}
                    className={error ? styles['form-input-error'] : styles['form-input-container']}
                >
                    <Form.Control
                        ref={inputRef}
                        name={name}
                        type="text"
                        placeholder={placeholder}
                        onChange={(value) => handleInputChange(value)}
                        className={styles['form-input']}
                        autoComplete="on"
                        value={inputValue}
                        errorMessage={null}
                        disabled={disabled}
                        {...rest}
                    />
                </InputGroup>
                <div className={styles['message-container']}>
                    <span className={styles['form-error-message']}>
                        {showErrorIcon && (
                            <img src="/assets/images/error.svg" alt="icon error" width={16} height={16} />
                        )}
                        {error}
                    </span>
                </div>
                {predictions?.length > 0 && showSuggestions && (
                    <div ref={suggestionsRef} className={styles['suggestions-container']}>
                        {predictions.map((prediction) => (
                            <div
                                className={styles['suggestions-text-icon']}
                                key={prediction.place_id}
                                onClick={() => handleSelect(prediction.description)}
                            >
                                <img
                                    src="/assets/images/location.svg"
                                    alt="icon info"
                                    width={16}
                                    height={16}
                                    style={{ marginTop: '4px' }}
                                />
                                <span className={styles['suggestion-text']}>{prediction.description}</span>
                            </div>
                        ))}
                    </div>
                )}
            </Form.Group>
        </>
    );
};

export default ExAutocompleteGmaps;
