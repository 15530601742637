export enum EErrorCode {
    UNKNOWN_ERROR = 1,
    WRONG_USERNAME_PASSWORD = 2,
    OPERATION_FORBIDDEN = 3,
    CREDIT_LIMIT_REACHED = 4,
    REASON_PROVIDED = 5,
    EXPIRED_PASSWORD = 6,
    MISSING_ACCOUNT = 7,
    MISSING_DISPATCH_ADDRESS = 8
}

