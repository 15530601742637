import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HelpIcon from '@material-ui/icons/Help';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import AdminSearchBar from 'components/AdminSearchBar';
import ChangePasswordModal from 'components/ChangePasswordModal';
import UserPanelMenu from 'components/UserPanelMenu';
import { PointOfSale } from 'components/point-of-sale';
import { Text } from 'components/text';
import { EX5057 } from 'config/flags';
import { useAuth } from 'context';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import * as gtag from 'utils/gtag';
import { useMenu, useModal, useUser } from 'utils/hooks';
import { getRoleById } from 'utils/string';
import useStyles from './Header.styles';

interface HeaderProps {
    posAlertDisabled?: boolean;
    disabledButtonProfile?: boolean;
    disablePointOfSale?: boolean;
}

function Header({ posAlertDisabled, disabledButtonProfile = true, disablePointOfSale = false }: HeaderProps) {
    //translation
    const { t } = useTranslation();
    const classes = useStyles();
    const { user, hasAuthorization } = useUser({});
    const router = useRouter();
    const profileMenu = useMenu();
    const { logout } = useAuth();
    const modal = useModal();

    const EX3467 = process.env.NEXT_PUBLIC_EX3467 === 'true';

    const roleUser = getRoleById(user?.role_id);

    let roleInSpanish;
    switch (roleUser) {
        case 'owner': {
            roleInSpanish = 'dueño';
            break;
        }
        case 'manager': {
            roleInSpanish = 'gerente';
            break;
        }
        case 'buyer': {
            roleInSpanish = 'comprador';
            break;
        }
        case 'admin': {
            roleInSpanish = 'admin';
            break;
        }
        case 'client': {
            roleInSpanish = 'cliente';
            break;
        }

        default:
            break;
    }

    const handleLogoClick = () => {
        localStorage.removeItem('clientId');
        localStorage.removeItem('typeId');
        router.push('/');
    };

    const handleClickConfig = () => {
        gtag.event({
            action: 'click',
            category: 'efficient-purchase',
            label: 'button__config',
            value: 0,
        });
        router.push(`/${router.query.clientId}/settings/POS`);
    };

    const handleClickChangePassGa = () => {
        gtag.event({
            action: 'click',
            category: 'efficient-purchase',
            label: 'button__change-password',
            value: 0,
        });
    };

    const handleClickLogoutGa = () => {
        gtag.event({
            action: 'click',
            category: 'efficient-purchase',
            label: 'button__log-out',
            value: 0,
        });
    };

    const handleClickChangePass = () => {
        handleClickChangePassGa();
        modal.toggle();
    };

    const handleClickLogout = () => {
        handleClickLogoutGa();
        localStorage.removeItem('country');
        localStorage.removeItem('pdvSelected');
        logout();
    };
    const handleHelpClick = () => window.open('https://ayuda.extendeal.com/');
    return (
        <AppBar className={classes.appBar} position="absolute">
            <ChangePasswordModal modal={modal} />
            <Toolbar className={classes.toolbar}>
                <img
                    className={classes.logo}
                    alt="extendeal_logo"
                    src="/assets/images/logo-extendeal.svg"
                    onClick={handleLogoClick}
                />

                {roleUser === 'admin' && <AdminSearchBar query={router?.query?.query} />}
                {router.pathname === '/my-orders' ||
                    (roleUser !== 'admin' && !disablePointOfSale && <PointOfSale alertDisabled={posAlertDisabled} />)}

                {disabledButtonProfile && (
                    <>
                        <div className={classes.toolbarButtonsContainer}>
                            {EX3467 ? (
                                <div className={classes.containerHelp}>
                                    <HelpOutlineIcon
                                        onClick={handleHelpClick}
                                        className={classes.helpIcon}
                                        style={{ marginRight: 8, width: 20 }}
                                    />
                                    <p onClick={handleHelpClick} className={classes.textHelper}>
                                        Ayuda
                                    </p>
                                </div>
                            ) : (
                                <HelpIcon
                                    onClick={handleHelpClick}
                                    className={classes.helpIcon}
                                    style={{ marginRight: 16 }}
                                />
                            )}

                            {EX3467 && <hr className={classes.separatorLine} />}
                            <IconButton onClick={profileMenu.handleMenu} color="inherit" style={{ paddingLeft: 16 }}>
                                {EX3467 ? <img src="/assets/images/accountCircle.svg" /> : <AccountCircleIcon />}
                                <div className={classes.containerAvatarText}>
                                    <Text fontSize="14px" fontWeight="bold">
                                        {user?.first_name} {user?.last_name}
                                    </Text>
                                    {EX3467 && (
                                        <Text fontSize="12px" fontWeight="normal" className={classes.roleText}>
                                            {roleInSpanish}
                                        </Text>
                                    )}
                                </div>
                                {EX3467 && (
                                    <div className={classes.chevronIcon}>
                                        {!!profileMenu.open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    </div>
                                )}
                            </IconButton>
                        </div>
                    </>
                )}
            </Toolbar>

            <UserPanelMenu
                {...{ user }}
                anchorEl={profileMenu.anchorEl}
                open={profileMenu.open}
                onClose={profileMenu.handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                getContentAnchorEl={null}
                style={{ top: 28, width: 280 }}
                actions={[
                    ...(hasAuthorization
                        ? []
                        : [
                              {
                                  label: !!EX5057
                                      ? t('settings.listPointsOfSale.sett_configTextPointsOfSale')
                                      : 'Configuración',
                                  onClick: handleClickConfig,
                                  icon: <SettingsOutlinedIcon />,
                                  className: 'button__config',
                              },
                          ]),
                    {
                        label: EX5057 ? t('home.changePassword1') : 'Cambiar Contraseña',
                        onClick: handleClickChangePass,
                        icon: <LockOutlinedIcon />,
                        className: 'button__change-password-2',
                    },
                    {
                        label: EX5057 ? t('header.header_dropdownThree') : 'Salir',
                        onClick: handleClickLogout,
                        icon: <ExitToAppOutlinedIcon />,
                        className: 'button__log-out',
                    },
                ]}
            />
        </AppBar>
    );
}

export default Header;
