import { apiPharmaInstance } from "utils/axios";
import { ResponseType, User } from "common/types";

export interface PostUserRequest {
  role_id: number;
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  pharmacy_name: string;
  pharmacy_address?: string;
}

export const createUser = async (request: User) => {
  const response = await apiPharmaInstance.post("/api/users", request);
  return response;
};

export const resendWelcomeEmail = async (userId: string | number) => {
  const response = await apiPharmaInstance.get(`/api/users/${userId}/resend-welcome-email`);
  return response;
};

export const getCurrentUser = async () =>
  await apiPharmaInstance.get<ResponseType<User>>("/api/users/me");

export const deleteUser = async (id: number) => {
  const response = await apiPharmaInstance.delete(`/api/users/${id}`);
  return response;
};

export const updateUser = async (id: number, request: User) => {
  const response = await apiPharmaInstance.patch(`/api/users/${id}`, request);
  return response.status === 200;
};

export const bulkDeleteUsers = async (toDelete: number[]) => {
  const response = await Promise.all(toDelete.map((id) => deleteUser(id)));
  return response;
};

export const recoveryPassword = async (request: any) => {
  const response = await apiPharmaInstance.post("/api/auth/reset", request);
  return response.status === 200;
};

export const resetPassword = async (request: any) => {
  const response = await apiPharmaInstance.post(
    "/api/auth/password/reset",
    request
  );
  return response.status === 200;
};

export const changePassword = async (request: any) => {
  const response = await apiPharmaInstance.post("/api/auth/password/change", request);
  return response;
};
export const recoveryPasswordTokenVerification = async (token: string) => {
  const response = await apiPharmaInstance.get(`/api/auth/find/${token}`);
  return response;
};
