var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"BZPeXCENdOwv7lxfIvVet"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN,
  environment: process.env.NODE_ENV !== "production" ? process.env.NODE_ENV : "production",
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1,
  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,
  replaysOnErrorSampleRate: 1.0,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    new Sentry.Replay({
      // Additional Replay configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  ignoreTransactions: [
    "/activateUser/[token]/",
    "/_error",
    "/efficient-purchase",
    "/[clientId]/settings/POS",
    "/[clientId]/efficient-purchase/[posId]",
    "/",
    "/login",
    "/[clientId]/settings/POS/[posId]/posDetails",
    "/clients/[clientId]",
    "[clientId]/settings/trade-agreements/editAgreement/[id]",
    "/pos/[clientId]/[posId]",
    "/[clientId]/efficient-purchase/[posId]/product-comparison",
    "/[clientId]/settings/trade-agreements",
    "/clients",
    "/[clientId]/efficient-purchase",
    "/login/new-password/[token]",
    "/about",
    "/pos/create/[client_id]",
    "/[clientId]/efficient-purchase/cart",
    "/[clientId]/efficient-purchase/search-results",
    "/1/efficient-purchase/",
    "/[clientId]/efficient-purchase/[posId]/confirmation",
    "/[clientId]/efficient-purchase/[posId]/order-sent",
    "/login/terms",
    "/clients/new",
    "/login/recovery",
    "/[clientId]/settings/trade-agreements/create",
    "/initial-config",
  ],
});
