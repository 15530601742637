import React, { createContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
// configs
import { EX5532 } from 'config/flags';
// apis
import { getERP } from 'api';
// utils
import { useUser } from 'utils/hooks';

export interface StatusProviderProps {
    setStatus(value: boolean): void;
    status: boolean;
    setControl(value: boolean): void;
    control: boolean;
    setRes(value: boolean): void;
    res: boolean;
    setResponse(value: boolean): void;
    response: boolean;
    count?: any;
    setCount(value: any): void;
    objSelect?: any;
    setObjSelect?: any;
    errorResponse?: any;
    setErrorResponse?: any;
    handleERPValue?: any;
    setCounterStep?(value: string): void;
    counterStep?: string;
    setIsTourOpen?(value: boolean): void;
    isTourOpen?: boolean;
    setDisplayModalOnBoarding?(value: boolean): void;
    displayModalOnBoarding?: boolean;
    hideSuggestionPDV?: boolean;
    setHideSuggestionPDV?(value: boolean): void;
}

const StatusContext = createContext<Omit<StatusProviderProps, 'children'>>(null);

export function StatusProvider({ children, ...props }) {
    const router = useRouter();
    const { clientId } = router.query || {};
    const [status, setStatus] = useState<boolean>(false);
    const [control, setControl] = useState<boolean>(false);
    const [response, setResponse] = useState<boolean>(false);
    const [errorResponse, setErrorResponse] = useState('');
    const [res, setRes] = useState<boolean>(true);
    const [count, setCount] = useState(1);
    const [objSelect, setObjSelect] = useState([]);
    const [counterStep, setCounterStep] = useState<string>('body');
    const [isTourOpen, setIsTourOpen] = useState<boolean>(true);
    const [displayModalOnBoarding, setDisplayModalOnBoarding] = useState<boolean>(true);
    const [hideSuggestionPDV, setHideSuggestionPDV] = useState<boolean>(false);

    const { user } = useUser({});
    // TO DO: cambiar a vista permitida, para evitar tantos paths
    const viewsNotAllowed = ['/[clientId]/efficient-purchase/[posId]/product-comparison'];

    useEffect(() => {
        if (!EX5532) {
            handleERPValue();
        } else {
            if (!viewsNotAllowed?.includes(router.pathname)) {
                handleERPValue();
            }
        }
    }, [router.query, user]);

    const handleERPValue = async () => {
        if (!!clientId) {
            let res = await getERP(clientId);
            setObjSelect(res?.data?.data);
        }
    };

    return (
        <StatusContext.Provider
            value={{
                status,
                setStatus,
                setControl,
                control,
                setRes,
                res,
                response,
                setResponse,
                count,
                setCount,
                objSelect,
                setObjSelect,
                errorResponse,
                setErrorResponse,
                handleERPValue,
                setCounterStep,
                counterStep,
                isTourOpen,
                setIsTourOpen,
                displayModalOnBoarding,
                setDisplayModalOnBoarding,
                hideSuggestionPDV,
                setHideSuggestionPDV,
            }}
        >
            {children}
        </StatusContext.Provider>
    );
}

export function useStatusContext() {
    const context = React.useContext(StatusContext);

    if (context === undefined) {
        throw new Error('useConfirmationContext must be used within a ConfirmationProvider');
    }

    return context;
}
