import { Identity } from 'common/types';

export enum BuyOperationType {
    DONT_SEND_ORDER_BUT_RETURNS_OK = 'dont_send_order_but_returns_ok',
    DONT_SEND_ORDER = 'dont_send_order',
    SEND_ORDER = 'send_order',
}

export type ConfirmationDrugManufacturer = {
    id: number;
    name: string;
    credit_available?: number;
    delivery?: any;
}

export interface DrugManufacturer extends Identity<number> {
    name: string;
    format_status?: any;
    buy_operation_type: BuyOperationType;
    credential_types?: any;
    deposits?: any;
    client_types?: any;
    url?: any;
    bulk_load_website?: boolean;
    credit_available?: number;
}

export default DrugManufacturer;
