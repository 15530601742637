// filter open search 
export const filterSearch = (product, currentSearch) => {
  let testReg = /^[a-zA-Z0-9ñÑ]+$/;
  let vowels = /[áéíóúÁÉÍÓÚ]/g;
  let spaces = /\s/g

  const currentSearchDetail = currentSearch.replace(/-/g, "");
  let filter;

  if (testReg.test(currentSearchDetail) || vowels.test(currentSearchDetail) || spaces.test(currentSearchDetail)) {
    return (filter = product.filter((item) => {
      const base = item?._source;
      if (base) {
        return (
          base.description
            .toLowerCase()
            .includes(currentSearchDetail.toLowerCase()) ||
          (String(base.ean[0]) &&
            String(base.ean[0]).includes(currentSearchDetail.toLowerCase())) ||
          (base.troquel &&
            base.troquel.includes(currentSearchDetail.toLowerCase())) ||
          (base.drug &&
            base.drug
              .toLowerCase()
              .includes(currentSearchDetail.toLowerCase())) ||
          (base.laboratory &&
            base.laboratory
              .toLowerCase()
              .includes(currentSearchDetail.toLowerCase()))
        );
      }
    }));
  }
  return filter;
};

export const filterWithRules = (product, currentSearch): boolean => {
  // regex
  let testReg = /^[a-zA-Z0-9ñÑ]+$/;
  let vowels = /[áéíóúÁÉÍÓÚ]/g;
  let spaces = /\s/g

  // regex by search word
  const currentSearchDetail = currentSearch.replace(/-/g, "");

  if (testReg.test(currentSearchDetail) || vowels.test(currentSearchDetail) || spaces.test(currentSearchDetail)) {
    const base = product;
    if (
      base._source.description
        .toLowerCase()
        .includes(currentSearchDetail.toLowerCase()) ||
      String(base._source.ean[0]) &&
      String(base._source.ean[0]).includes(currentSearchDetail.toLowerCase()) ||
      base.troquel &&
      base.troquel.includes(currentSearchDetail.toLowerCase()) ||
      base.drug &&
      base.drug
        .toLowerCase()
        .includes(currentSearchDetail.toLowerCase()) ||
      base.laboratory &&
      base.laboratory
        .toLowerCase()
        .includes(currentSearchDetail.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    };
  };
  return false;
};