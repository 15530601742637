import { apiPharmaInstance } from 'utils/axios';
import { ApiCredential, ResponseType } from 'common/types';

export const createApiCredential = async (request: any) => {
    request.password = window.btoa(request.password);

    return await apiPharmaInstance.post<ResponseType<ApiCredential>>('/api/api-credential', request);
};

export const uploadFormat = async (file: any, apiCredentialId: any) => {
    await apiPharmaInstance.post(`/api/api-credential/${apiCredentialId}/upload-format-unknown`, file);
};

export const updateApiCredential = async (id, request) => {
    if (request?.password) {
        request.password = window.btoa(request.password);
    }

    return await apiPharmaInstance.patch<ResponseType<ApiCredential>>(`/api/api-credential/${id}`, request);
};
/***
 * @deprecated
 *
 *
 * */
export const updateApiCredentialOrders = async (order_id, apiCredentialId, request) => {
    request.password = window.btoa(request.password);

    await apiPharmaInstance.patch<ResponseType<ApiCredential>>(
        `/api/orders/${order_id}/api-credential/${apiCredentialId}`,
        request,
    );
};
export const apiCredentialRetry = async (order_id, apiCredentialId) => {
    await apiPharmaInstance.post(`/api/orders/${order_id}/retry/${apiCredentialId} `);
};
export const deleteApiCredential = async (apiCredentialId) => {
    return await apiPharmaInstance.delete(`/api/api-credential/${apiCredentialId}`);
};

export const deleteAllApiCredential = async (drugManufacturerId, point_of_sale_id) => {
    const config = {
        data: {
            point_of_sale_id: point_of_sale_id,
        },
    };
    return await apiPharmaInstance.delete(`/api/drug-manufacturers/${drugManufacturerId}/delete-credentials`, config);
};

export const getApiCredential = async (apiCredentialId: any): Promise<ResponseType<any>> => {
    return await apiPharmaInstance.get(
        `/api/api-credential?filters=%7B%0A%20%20%22point_of_sale_id%22%3A%20${apiCredentialId}%0A%7D`,
    );
};

export const getApiCredentialInvalid = async (pointOfSaleId: number): Promise<ResponseType<any>> => {
    const EX2640 = process.env.NEXT_PUBLIC_EX2640;

    if (EX2640 === 'true') {
        return await apiPharmaInstance.get(`/api/api-credential/invalid-status/${pointOfSaleId}`);
    } else {
        return Promise.resolve({
            data: {
                data: [],
            },
        });
    }
};

export const saveAddress = async (id: any, addressSelected: any) => {
    let addressId = null;
    if (typeof addressSelected === 'object' && addressSelected?.id) {
        addressId = addressSelected.id;
    } else if (typeof addressSelected === 'number') {
        addressId = addressSelected;
    }

    return await apiPharmaInstance.post(`/api/api-credential/${id}/set-dispatch-address`, {
        dispatch_address_id: addressId,
    });
};

export const savePaymentMethod = async (credentialId: any, paymentMethod: any) => {
    return await apiPharmaInstance.post(`api/api-credential/${credentialId}/set-payment-method`, {
        payment_method_id: paymentMethod?.id,
    });
};

export const getCatalogs = async () => {
    return await apiPharmaInstance.get('/api/catalogs/');
};

export const saveCatalogForDrugManufacturer = async (
    point_of_sale_id: number,
    client_identifier: string,
    drug_manufacturer_id: number,
    catalog_id: number,
) => {
    return await apiPharmaInstance.post('/api/api-credential/with-catalog', {
        point_of_sale_id: point_of_sale_id,
        client_identifier: client_identifier,
        drug_manufacturer_id: drug_manufacturer_id,
        catalog_id: catalog_id,
    });
};

export const updateCatalogForDrugManufacturer = async (
    apiCredentialId: number,
    point_of_sale_id: number,
    client_identifier: string,
    drug_manufacturer_id: number,
    catalog_id: number,
) => {
    return await apiPharmaInstance.patch(`/api/api-credential/with-catalog/${apiCredentialId}`, {
        point_of_sale_id: point_of_sale_id,
        client_identifier: client_identifier,
        drug_manufacturer_id: drug_manufacturer_id,
        catalog_id: catalog_id,
    });
};
