import React from "react";
import { Button } from "rsuite";
import style from "./ButtonGhost.module.css";

interface IButtonGhost {
  icon?: any;
  text?: any;
  disabled?: boolean;
  height: number | string;
  width: number | string;
  onClick?: any;
  margin?: string;
}

const ButtonGhost = ({
  icon,
  text,
  disabled,
  height,
  width,
  onClick,
  margin,
}: IButtonGhost) => {
  return (
    <Button
      onClick={onClick}
      className={style["button-style"]}
      disabled={disabled}
      style={{ width: width, height: height, margin: margin }}
      appearance="subtle"
      startIcon={icon ? icon : ""}
    >
      {text}
    </Button>
  );
};

export default ButtonGhost;
