// react
import { useEffect, useState, useRef } from 'react';
// components
import { DrawerAlertSelect } from '../DrawerAlertSelect';
// rsuite
import { CheckPicker, Checkbox, Button } from 'rsuite';
// styles
import style from './DrawerAlertClient.module.css';
// hooks
import { useClientsOnlyAlerts } from 'utils/hooks/use-clients';
// utils
import { auxiliary } from './utils';

const DrawerAlertClient = ({
    country,
    setClients,
    clients: client,
    initialValue,
    setFormChanged,
    lastId,
    setIsFormRequired,
    isFormRequired,
    disabled,
}) => {
    // ref
    const buttonRef = useRef(null);

    // state
    const [pagination, setPagination] = useState({
        pageIndex: 1,
        rows: '9999',
        search: '',
        sort: '',
        ordering: '',
    });
    const { pageIndex, rows, search, sort, ordering } = pagination;
    const [hasError, setHasError] = useState(false);

    // hooks
    const { clients, mutateClients } = useClientsOnlyAlerts({
        pageIndex,
        rows,
        search,
        sort,
        ordering,
        country,
    });

    // logics
    const actions = auxiliary();

    const recordset = actions.recordsetClients(clients);
    const allValues = actions.selectAllValues(recordset);

    useEffect(() => {
        mutateClients();
    }, [country]);

    useEffect(() => {
        if (initialValue && initialValue.length > 0 && !!lastId) {
            setClients(initialValue);
        } else {
            setClients([]);
        }
    }, []);

    return (
        <DrawerAlertSelect label="Cliente">
            <CheckPicker
                ref={buttonRef}
                value={client}
                searchable={true}
                valueKey="value"
                disabled={disabled}
                className={!!hasError && client.length === 0 ? style['text-error-input'] : style['checkbox-picker']}
                block
                placeholder={'Seleccioná un cliente'}
                data={recordset}
                onClean={(evt) => actions.handleResetValues(setClients, setHasError)}
                onChange={(evt) =>
                    actions.handleClientChange(evt, setClients, isFormRequired, setIsFormRequired, 'client')
                }
                renderExtraFooter={() => (
                    <div className={style['checkAll-container']}>
                        <Checkbox
                            indeterminate={client?.length > 0 && client?.length < allValues?.length}
                            checked={client?.length === allValues?.length}
                            onChange={() =>
                                actions.handlerSelectAllChange(
                                    setClients,
                                    client,
                                    allValues,
                                    isFormRequired,
                                    setIsFormRequired,
                                    'client',
                                )
                            }
                            inline
                        >
                            Seleccionar todo
                        </Checkbox>
                        <Button
                            appearance="primary"
                            className={style['container-button']}
                            onClick={(evt) =>
                                actions.handleApplyClick(
                                    evt,
                                    buttonRef,
                                    setClients,
                                    clients,
                                    isFormRequired,
                                    setIsFormRequired,
                                    'client',
                                    setFormChanged,
                                )
                            }
                        >
                            Aplicar
                        </Button>
                    </div>
                )}
            />
        </DrawerAlertSelect>
    );
};

export default DrawerAlertClient;
