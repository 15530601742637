import useSWR from 'swr';
// configs
import { EX5532 } from 'config/flags';

export const useDrugManufacturersByOrderId = (orderId) => {
    if (EX5532) {
        const { data: drugManufacturersResponse, mutate: mutateDrugManufacturers } = useSWR(
            orderId !== undefined ? `api/orders/${orderId}/drugManufacturers` : null,
            {
                revalidateOnFocus: false, // No revalidar al recuperar el foco
                dedupingInterval: 300000, // 5 minutos para evitar solicitudes duplicadas
                shouldRetryOnError: false, // No reintentar en caso de error
            },
        );
        return { drugManufacturersResponse, mutateDrugManufacturers };
    } else {
        return {};
    }
};
