import { Appearance, Size } from "../interfaces/Button.interface"
import style from '../Button.module.css';
import { InfoOutlined } from "@mui/icons-material";


export const btnAppearance: {[key in Appearance]: string} = {
    primary: style['button-primary'],
    link: style['button-link'],
    ghost: style['button-ghost'],
    outline: style['button-outline'],
    alternative: style['button-alternative'],
    default: '',
    subtle: '',
}

export const btnSize: {[key in Size]: string} = {
    default: style['button-size-default'],
    lg: style['button-size-lg'],
    md: style['button-size-md'],
    sm: style['button-size-sm'],
    xs: ''
}

export const btnStatus = {
    success: {
        styles: style['button-success'],
        icon: <img src={'/assets/images/navigationCheck.svg'} />,
    },
    warning: {
        styles: style['button-warning'],
        // icon: <img src={'/assets/images/error.svg'} />,
        icon: <span style={{display: 'flex'}}><InfoOutlined /></span>
    },
    initial: {
        styles: '',
        icon: ''
    }
};