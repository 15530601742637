export interface ResponseType<T> {
  data: T;
  percentages_distributed?:any;
  drug_manufacturers_available?:any;
  without_distribution?:any;
  status?:number;
  meta?: {
    pagination: Pagination;
  };
}

export interface Filters {
  pageIndex?: number;
  search?: any;
  sort?: any;
}

export interface Timestamp {
  created_at: string;
  deleted_at?: string | null;
  updated_at: string;
}

export interface Identity<T> {
  id: T;
}

export interface Pagination {
  count: number;
  current_page: number;
  links: { next: string };
  per_page: number;
  total: number;
  total_pages: number;
}

export interface Pharmacy extends Timestamp, Identity<number> {
  name: string;
  user_id: number;
}

export interface PointOfSale extends Timestamp, Identity<number> {
  name: string;
  users: Array<User>;
  address: string;
  client_id: string;
}

export interface Order extends Timestamp, Identity<number> {
  user_id: number;
}
export interface OrderProduct extends Timestamp, Identity<number> {
  barcode: string;
  description: string;
  quantity: number;
  order_id: number;
  selectedProduct: {
    drug_manufacturer_id: number;
    api_product_id: number;
  };
}

export enum Credentials {
  ZETTI = 4,
}
export enum Role {
  Buyer = 2,
  Manager = 6,
  Admin = 7,
  Client = 20,
  Owner = 21,
  VendorAdmin = 31,
  VendorManager = 32,
}

export interface Client extends Timestamp, Identity<number> {
  name: String;
  email: String;
  phone_number: String;
  points_of_sale_qty: number;
}

export enum UserStatus {
  PENDING_ACTIVATION = 1,
  ACTIVE = 2,
}

export interface User extends Timestamp, Identity<number> {
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  enabled: boolean;
  pointsOfSale: PointOfSale[];
  pharmacies: Pharmacy[];
  apiCredentials: ApiCredential[];
  orders: Order[];
  role_id: Role;
  client_id: number;
  has_full_pos_access: boolean;
  password?: string;
  status: any;
  client:any,
}

export enum ApiCredentialStatus {
  Pending = "pending",
  Enabled = "enabled",
}
export interface ApiCredential extends Timestamp, Identity<number> {
  username: string;
  password: string;
  decoded_password: boolean;
  client_identifier: string;
  user: User;
  drug_manufacturer: DrugManufacturer;
  status: ApiCredentialStatus;
}
export enum BuyOperationType {
  DONT_SEND_ORDER_BUT_RETURNS_OK = "dont_send_order_but_returns_ok",
  DONT_SEND_ORDER = "dont_send_order",
  SEND_ORDER = "send_order",
}

export interface DrugManufacturer extends Identity<number> {
  name: string;
  buy_operation_type: BuyOperationType;
}

export interface JWTResponse {
  access_token: string;
  expirates_at: string;
  message: string;
  token: string;
  token_type: string;
}

export interface Order extends Timestamp, Identity<number> {
  user: User;
  status: {
    id: number;
    name: string;
  };
}

export interface ApiProduct extends Identity<number> {
  code: string;
  barcode: string;
  description: string;
  price_with_discount: number;
  iva_percentage: number;
  available: boolean;
  api_credential_id: number;
  drug_manufacturer_id: number;
  order_id: number;
  total: number;
  discountPercentage: string;
  quantityDiscountApplies: boolean;
  discounts: Discount[];
  drug_manufacturer: DrugManufacturer;
}

export interface Product extends Identity<number> {
  delivery_id: number;
  api_product_id: number;
  quantity: number;
  price: number;
  api_product: ApiProduct;
}

export enum TreackStatusNameType {
  PROCESSED = 1,
  IN_PROGRESS = 2,
}
interface TrackStatus extends Identity<Number> {
  name: TreackStatusNameType;
}
export interface OrderDelivery extends Identity<string> {
  order_id: number;
  drug_manufacturer_id: number;
  total: number;
  total_percentage: number;
  drug_manufacturer: DrugManufacturer;
  track_number?: number | "Envío manual" | "No provisto";
  track_status: TrackStatus;
  order: Order;
  products: Product[];
  status: {
    message: "ok" | "error";
    ws_status_code: 200 | null;
  };
}

export interface Discount extends Identity<number> {
  min_quantity: number;
  price: number;
  api_product_id: number;
}

export enum DrugManufacturersId {
  DrogueriasDelSud = 1,
  SuizoArgentina = 2,
  Disval = 3,
  Asoprofarma = 4,
  MonroeAmericana = 5,
}

export enum FORMAT_STATUS {
  VALIDATED = "VALIDATED",
  VALIDATION_PENDING = "VALIDATION_PENDING",
  UNKNOWN = "UNKNOWN",
}

export enum TYPES_MESSAGES {
  DELETE_ONE_PRODUCT = "Has eliminado el producto del carrito.",
  DELETE_CART = "Se eliminarán todos los productos del carrito.",
  YOU_EMPTY_CART = "Has vaciado el carrito.",
  EMPTY_CART = "Vaciar carrito",
  EMPTY_CART_BY_ONGOING_COMPARISON = "El carrito se ha vaciado porque ya has iniciado una comparativa.",
  EMPTY_CART_BY_FINISH_COMPARISON = "El carrito se ha vaciado porque ya lo has utilizado para finalizar una compra.",
  INIT_COMPARISION = "Iniciar comparativa",
  INIT_COMPARISION_MSG = "Se iniciará la comparativa con los productos seleccionados.",
  EMPTY_CART_MSG = "Durante este proceso se vaciará el carrito.",
}

export enum EXPIRED_NOTIFICATION_ID {
  NEAR_EXPIRED = 1,
  EXPIRED = 2
}